import React, { Component } from "react";
import firebase from "../../firebase";
import { getDomain } from "../../helpers/utils";

export default class DoSignin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null
    };
  }

  componentDidMount = () => {
    if (
      firebase.auth().isSignInWithEmailLink(window.location.href) &&
      !this.state.error
    ) {

      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      var email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt(
          "Please provide your email for confirmation\nAre you signing in with the same browser?"
        );
      }
      // The client SDK will parse the code from the link for you.
      firebase
        .auth()
        .signInWithEmailLink(email, window.location.href)
        .then(result => {
          // Clear email from storage.
          window.localStorage.removeItem("emailForSignIn");
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser

          firebase
            .firestore()
            .collection("orgs")
            .doc(getDomain())
            .get()
            .then(org => {
              if (!org.exists || org.data().requiresSetup) {
                this.props.history.push("/setup");
              } else {
                if (result.additionalUserInfo.isNewUser) {
                  window.localStorage.setItem("newUser", "true");
                  this.props.history.push("/profile");
                }else{
                  this.props.history.push("/dashboard");
                }
              }
            });
        })
        .catch(error => {
          this.setState({ error });

          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    }
  }

  componentWillUpdate = () =>{
  }

  render() {

    if (this.state.error) {
      return (
        <div className="m-4">
          <h1>Oops, there's been an issue.</h1>
          <p>
            You've most likely used an old email to sign in which has expired.
          </p>
          <a href="/">Sign in again</a>
          <div className="mt-4">
            <code>
              Technical details:
              <br />
              <br />
              {this.state.error.code}
              <br />
              {this.state.error.message}
            </code>
          </div>
        </div>
      );
    } else {
      return <h1 className="text-center mt-4">Signing in...</h1>;
    }
  }
}
