import React from "react";
import { Row, Col } from "react-bootstrap";
import { emojiFor, pillForStatus } from "../helpers/utils.js";

export default function Person(props) {
  return (
    <Row
      xs={4}
      md={12}
      className={`person ${props.freshness} ${props.condition}`}
    >
      <Col xs="3" md="2">
        {props.name}
      </Col>
      {props.condition === "working" && (
        <React.Fragment>
          <Col xs="3" md="2">
            {pillForStatus(props.status)}
          </Col>
          <Col xs="3" md="2">
            <Row>
              <Col xs="auto" className="dashboard-emoji p-0">
                {emojiFor(props.location)}{" "}
              </Col>
              <Col
                xs="auto"
                className=" text-capitalize small pr-0 pl-1 align-self-center"
              >
                {props.location}
              </Col>
            </Row>
          </Col>
        </React.Fragment>
      )}
      <Col xs="3" md="2">
        {props.condition ? (
          <Row>
            <Col xs="auto" className="dashboard-emoji p-0">
              {emojiFor(props.condition)}{" "}
            </Col>
            <Col
              xs="auto"
              className=" text-capitalize small pr-0 pl-1 align-self-center"
            >
              {props.condition.replace(/-/g, " ")}
            </Col>
          </Row>
        ) : (
          "Not provided"
        )}
      </Col>{" "}
      {props.message !== undefined && props.message.trim() !== "" ? (
        <Col
          xs="12"
          md={{ offset: props.condition === "working" ? 0 : 4, span: 4 }}
        >
          <small className="small">
            <b>Status</b> {props.message}
          </small>
        </Col>
      ) : (
        ""
      )}
    </Row>
  );
}
