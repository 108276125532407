import React from "react";
import { Form, Button, ButtonGroup } from "react-bootstrap";

export default function AvailabilitySelect(props) {

  const size = props.size || "lg";
  return (
    <Form.Group aria-label="status" className="text-center">
      <Form.Text>Can you be contacted?</Form.Text>
      <ButtonGroup
      style={{backgroundColor: "white"}}
        aria-label="Status"
        onClick={event => props.updateProfile("status", event.target.value)}
      >
        <Button
          value="available"
          variant={
            (props.availability !== "available" ? "outline-" : "") + "success"
          }
          size={size}
        >
          Available
        </Button>{" "}
        <Button
          value="away"
          variant={
            (props.availability !== "away" ? "outline-" : "") + "warning"
          }
        >
          Away, be right back
        </Button>
        <Button
          value="busy"
          variant={(props.availability !== "busy" ? "outline-" : "") + "danger"}
        >
          Busy, do not disturb
        </Button>{" "}
      </ButtonGroup>
    </Form.Group>
  );
}
