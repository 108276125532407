import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { Redirect } from "react-router-dom";
import firebase from "../../firebase";
import SignInRow from "../../component/SignInRow"

export default class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailSent: false
    };
  }

  sendEmail = () => {
    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be whitelisted in the Firebase Console.
      url: window.location.origin + "/dosignin",
      // This must be true.
      handleCodeInApp: true
    };

    firebase
      .auth()
      .sendSignInLinkToEmail(this.state.email, actionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem("emailForSignIn", this.state.email);
        debugger;
        this.setState({ emailSent: true });
      })
      .catch(error => {
        // Some error occurred, you can inspect the code: error.code
        this.setState({ error });
      });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.sendEmail();
  };

  handleChange = event => {
    localStorage.setItem("email", event.target.value);
    this.setState({ email: event.target.value });
  };

  render() {
    if (this.state.emailSent) {
      return <Redirect to="/checkemail" />;
    }
    return (
      <Container fluid className="p-0">
        <Container>
        <Row className="my-4">
          <Col>
            <h1 className="display-3 text-center">Pricing</h1>
            <h3 className="text-center text-secondary">
              Working Status is free to get started.
              <br />
              <small>
                Payment information is requested once quotas reached.
              </small>
            </h3>
          </Col>
        </Row>
        <Row className="my-4">
          <Col className="mx-2">
            <Row>
              <Col className="free-tier-pricing price-box p-4">
                <h1>Free</h1>
                <h1 className="display-1">
                  <small>&pound;</small>0
                </h1>
                <strong className="text-right d-block">&nbsp;</strong>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <h4>Free includes</h4>
                <ul>
                  <li>Free for up to 20 people</li>
                  <li>5,000 status updates a month</li>
                </ul>
              </Col>
            </Row>
          </Col>
          <Col className="mx-2">
            <Row>
              <Col className="standard-tier-pricing price-box p-4">
                <h1>Standard</h1>
                <h1 className="display-1">
                  <small>&pound;</small>8
                </h1>
                <strong className="text-right d-block">Billed monthly</strong>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <h4>Standard includes</h4>
                <ul>
                  <li>Up to 50 people</li>
                  <li>Unlimited status updates</li>
                </ul>
              </Col>
            </Row>
          </Col>
          <Col className="mx-2">
            <Row>
              <Col className="plus-tier-pricing price-box p-4">
                <h1>Plus</h1>
                <h1 className="display-1">
                  <small>&pound;</small>24
                </h1>
                <strong className="text-right d-block">Billed monthly</strong>
              </Col>
            </Row>
            <Row>
              <Col className="mt-3">
                <h4>Plus includes</h4>
                <ul>
                  <li>Up to 150 people</li>
                  <li>Unlimited status updates</li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h3 className="display-4 text-center text-dark">
              Have a question, need further integration?
            </h3>
            <h5 className="text-right text-center">
              <a href="mailto:chris@onebrightspace.com">Get in touch</a>
            </h5>
          </Col>
        </Row>
        </Container>
        <hr />
        <SignInRow />

      </Container>
    );
  }
}
