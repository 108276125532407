import React from "react";
import { Link } from "react-router-dom";

export default function Footer(props) {
  return (
    <footer className="text-center">
      {props.user ? (
        <p>
          <small>
            Signed in as <em>{props.user.email}</em>
          </small>
        </p>
      ) : (
        ""
      )}
      <div>
        <a href="mailto:chris@onebrightspace.com">Support</a> &#183;{" "}
        <Link to="/privacy">Privacy Policy</Link>
      </div>
      <small>A One Bright Space productivity tool.</small>
      <p>
        <small>
          Registered Number: 5027696. Registered in England and Wales.
        </small>
      </p>
    </footer>
  );
}
