import React, { createContext } from "react";
import { getDomain } from "../helpers/utils";
import firebase from "../firebase";
import { useDocumentData } from "react-firebase-hooks/firestore";


export const OrgContext = createContext();

export const OrgContextProvider = props => {
  const [org, loading, error] = useDocumentData(
    firebase
      .firestore()
      .collection("orgs")
      .doc(getDomain()),
    {}
  );

  return (
    <OrgContext.Provider value={[org, loading, error]}>
      {props.children}
    </OrgContext.Provider>
  );
};