import React, { useState } from "react";
import WelcomeNewUser from "./WelcomeNewUser";

export default function Message(props) {
  const isNewUser = window.localStorage.getItem("newUser");
  const [showNewUser, setNewUserShowing] = useState(
    isNewUser ? true : false
  );

  const toggleWelcomeShowing = () => {
    window.localStorage.removeItem("newUser")
    setNewUserShowing(!showNewUser);
  }


  return (
    <React.Fragment>
      {showNewUser && <WelcomeNewUser setShowing={toggleWelcomeShowing} />}
    </React.Fragment>
  );
}
