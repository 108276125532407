import React, { Component } from "react";

import {
  FormControl,
  Button,
  Form,
  Col,
  Row,
  InputGroup
} from "react-bootstrap";
import { Redirect } from "react-router-dom";
import firebase from "../firebase";

export default class Signin extends Component {
  constructor(props) {
    super(props);

    const rememberedEmail = localStorage.getItem("email", "");

    this.state = {
      email: rememberedEmail,
      emailSent: false
    };
  }

  sendEmail = () => {
    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be whitelisted in the Firebase Console.
      url: window.location.origin + "/dosignin",
      // This must be true.
      handleCodeInApp: true
    };

    firebase
      .auth()
      .sendSignInLinkToEmail(this.state.email, actionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem("emailForSignIn", this.state.email);
        window.localStorage.setItem("email", this.state.email);
        this.setState({ emailSent: true });
      })
      .catch(error => {
        // Some error occurred, you can inspect the code: error.code
        alert(error);
        this.setState({ error });
      });
  };

  handleSubmit = event => {
    if (!this.state.email.includes("@")) {
      alert("This doesn't appear to be a valid email.");
    }
    event.preventDefault();
    this.sendEmail();
  };

  handleChange = event => {
    localStorage.setItem("email", event.target.value);
    this.setState({ email: event.target.value });
  };

  render() {
    if (this.state.emailSent) {
      return <Redirect to="/checkemail" />;
    }

    return (
      <div
        id="sign-in-row"
        style={{ backgroundColor: "#ee4266" }}
        className="p-0"
      >
        <Row className="justify-content-center mx-3 p-3 text-white">
          <Col className="text-center">
            <h2>Try or Sign in</h2>
            <blockquote className="blockquote">
              No passwords, no signup form. Just enter your email, we'll send
              you a link and you're in.
            </blockquote>
            <Row className="justify-content-center">
              <Col md="6">
                <Form onSubmit={this.handleSubmit}>
                  <InputGroup>
                    <FormControl
                      placeholder="someone@myorg.org"
                      value={this.state.email}
                      onChange={this.handleChange}
                      size="lg"
                    />

                    <InputGroup.Append>
                      <Button variant="primary" type="submit">
                        Sign up
                      </Button>{" "}
                    </InputGroup.Append>
                  </InputGroup>
                  <p>
                    This email <strong>must</strong> be your work email, not a
                    generic gmail account.
                  </p>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}
