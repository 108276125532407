import React from "react";
import {Link} from "react-router-dom";
import {Container} from "react-bootstrap";

export default function CheckEmail() {
  return (
    <Container className="mt-4">
      <h1><b>Check your email.</b></h1>
      <h2>We've sent an email to {localStorage.getItem("email", "")}.</h2>
      <p>It may take a minute to come through.</p>
      <p>
        <Link to="/">Send again</Link>, if you didn't receive it.
      </p>
    </Container>
  );
}
