import firebase from 'firebase/app';
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage"

const firebaseConfig = {
    apiKey: "AIzaSyBXoXzAg2YCg-EcWB3l4htKYLkfSwm_ad4",
    authDomain: "availability-bbnpa.firebaseapp.com",
    databaseURL: "https://availability-bbnpa.firebaseio.com",
    projectId: "availability-bbnpa",
    storageBucket: "availability-bbnpa.appspot.com",
    messagingSenderId: "100524446283",
    appId: "1:100524446283:web:ea748fcb3f4645e151f3ce",
    measurementId: "G-KWXW24N0JC"
  };
firebase.initializeApp(firebaseConfig);
export default firebase;