import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import Person from "./Person";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const contactable = ["available", "away", "busy"];
const location = ["office", "home", "mobile"];
const messagesBusyInOffice = [
  "",
  "",
  "",
  "",
  "On a call",
  "Ignoring email until 4pm",
  "In a video call"
];

const messageAwayInOffice = ["Gone to lunch", "Be right back", "", "", ""];
const messageAwayHome = [
  "Having a cuppa",
  "Feeding my children",
  "Putting the washing on",
  "",
  "",
  ""
];

const messageAwayMobile = [
  "Driving, stopping soon for lunch",
  "Undertaking survey",
  "Boarding a flight to Miami",
  "",
  "",
  ""
];
const messageAvail = [
  "Finishing at 3pm today.",
  "Will be in late tomorrow morning.",
  "Not got my work mobile on me.",
  "Phone out of juice, call landline.",
  "",
  "",
  ""
];

export default class LiveDashboard extends Component {
  constructor(props) {
    super(props);


    this.state = {
      demoUserProfile: {
        name: "Amy Scott",
        condition: "checked-out",
        location: "office",
        status: "available",
      },
      timers: []
    };
  }

  componentDidMount() {
    const names = [
      "Mary Watts",
      "Chris Baker",
      "Rob Racer",
      "Hans Martin",
      "John Higgs"
    ];

    const newDemoUsers = names.map(name => {
      return { name, ...this.generateRandomPersonState() };
    });

    this.setState(
      { randomDemoUsers: newDemoUsers },
      this.startRandomDashboardUpdates
    );

    this.timers = [];
  }


  componentDidUpdate(prevProps, prevState) {
      if (prevProps.isUpdating !== this.props.isUpdating) {
        prevState.isUpdating ? this.startRandomDashboardUpdates() : this.stopRandomDashboardUpdates();
      }
  }

  componentWillUnmount() {
    this.stopRandomDashboardUpdates();
  }
  
  startRandomDashboardUpdates() {
      this.state.randomDemoUsers.forEach((person, idx) => {
        this.randomTimeOut(person, idx);
      })
  }

  stopRandomDashboardUpdates() {
    this.timers.forEach(timeout => clearTimeout(timeout));
  }

  randomTimeOut(person, idx) {
    const maxDelay = 6000;
    const minDelay = 2000;
    let newRandomUsers = this.state.randomDemoUsers.slice();
    newRandomUsers[idx] = {
      ...this.generateRandomPersonState(),
      name: person.name
    };

    this.setState({ randomDemoUsers: newRandomUsers }, () =>
      this.timers[idx] = setTimeout(
        () => this.randomTimeOut(person, idx),
        Math.random() * (maxDelay - minDelay + 1) + minDelay
      )
    );
  }

  peopleAvailable() {
    if (!this.state.randomDemoUsers) return 0;
    return this.state.randomDemoUsers
      .concat([this.state.demoUserProfile])
      .filter(
        user => user.status === "available" && user.condition !== "checked-out"
      ).length;
  }

  generateRandomPersonState() {
    let newProfileState = { message: "", condition: "working" };

    newProfileState["status"] =
      contactable[Math.floor(Math.random() * contactable.length)];
    newProfileState["location"] =
      location[Math.floor(Math.random() * location.length)];
    
      switch (newProfileState["status"]) {
      case "available":
        if (newProfileState["location"] === "office") {
          newProfileState["message"] =
            messageAvail[Math.floor(Math.random() * messageAvail.length)];
        } else if (newProfileState["location"] === "mobile") {
          newProfileState["message"] =
            messageAvail[Math.floor(Math.random() * messageAvail.length)];
        } else if (newProfileState["location"] === "home") {
          newProfileState["message"] =
            messageAvail[Math.floor(Math.random() * messageAvail.length)];
        }
        break;
      case "away":
        if (newProfileState["location"] === "office") {
          newProfileState["message"] =
            messageAwayInOffice[
              Math.floor(Math.random() * messageAwayInOffice.length)
            ];
        } else if (newProfileState["location"] === "mobile") {
          newProfileState["message"] =
            messageAwayMobile[
              Math.floor(Math.random() * messageAwayMobile.length)
            ];
        } else if (newProfileState["location"] === "home") {
          newProfileState["message"] =
            messageAwayHome[Math.floor(Math.random() * messageAwayHome.length)];
        }
        break;
      case "busy":
        if (location === "office") {
          newProfileState["message"] =
            messagesBusyInOffice[
              Math.floor(Math.random() * messagesBusyInOffice.length)
            ];
        }
        break;
      default:
    }

    return newProfileState;
  }

  render() {
    return (
      <Row className="liveDashboard justify-content-center py-4">
        <Col lg={12}>
          <h3 className="display-4 text-center text-dark">
            <FontAwesomeIcon icon="clipboard-list" />
            <br />
            Live Dashboard
          </h3>
          <h5 className="text-center mb-3">See who's working and where</h5>
        </Col>
        <Col lg={10}>
          <React.Fragment>
            <Row className="py-2 rounded-top" style={{ backgroundColor: "#efefef"}}>
              <Col xs={3} md={2}>
                <strong>Finance</strong>
              </Col>
              <Col xs={9} md="auto">
                <span>{this.peopleAvailable()}</span> <span>available</span>
              </Col>
            </Row>
            {this.state.randomDemoUsers &&
              this.state.randomDemoUsers.map(person => {
                return (
                  <Person key={person.name} {...person} freshness="fresh" />
                );
              })}
            <Person
              key="demoUserProfile"
              {...this.props.demoPerson}
              freshness="fresh"
            />
          </React.Fragment>
        </Col>
      </Row>
    );
  }
}
