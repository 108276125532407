import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import firebase from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import Footer from "./component/Footer";

//Contexts
import { OrgContextProvider } from "./context/OrgContext";

//Routes (Pages)
import Home from "./pages/public/Home";
import PrivacyPolicy from "./pages/public/PrivacyPolicy";
import Pricing from "./pages/public/Pricing";
import CheckEmail from "./pages/sign-in-flow/CheckEmail";
import DoSignin from "./pages/sign-in-flow/DoSignin";

//Components
import Navigation from "./component/Navigation";


import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faMinus,
  faPlus,
  faCog,
  faClipboardList,
  faUsers,
  faUserShield,
  faLaptopHouse,
  faServer,
  faStar,
  faIdBadge,
  faEnvelopeOpen,
  faCheckCircle,
  faUserCircle
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faCheckCircle,
  faMinus,
  faPlus,
  faCog,
  faClipboardList,
  faUsers,
  faUserShield,
  faLaptopHouse,
  faServer,
  faStar,
  faIdBadge,
  faEnvelopeOpen,
  faUserCircle
);

//Lazy load main app
const Dashboard = lazy(() => import("./pages/authenticated/Dashboard"));
const Profile = lazy(() => import("./pages/authenticated/Profile"));
const Settings = lazy(() => import("./pages/authenticated/Settings"));
const SetupOrg = lazy(() => import("./pages/authenticated/SetupOrg"));

function PrivateRoute({ children, user, requiresSetup, ...rest }) {
  return (
    <OrgContextProvider>
    <Route
      {...rest}
      render={({ location }) => {
        if (user) {
          return children;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: { from: location }
              }}
            />
          );
        }
      }}
    />
    </OrgContextProvider>
  );
}

export default function App(props) {
  const [user, initialising, error] = useAuthState(firebase.auth());

  if (initialising) return <p>Loading</p>;

  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <div>
          {error && <code>{JSON.stringify(error)}</code>}
          <Switch>
            <Route path="/privacy">
              <Navigation user={user} />
              <PrivacyPolicy />
            </Route>
            <Route path="/checkemail" component={CheckEmail} />
            <Route path="/pricing">
              <Navigation user={user} />
              <Pricing />
            </Route>
            />
            <Route path="/dosignin" component={DoSignin} />
            <PrivateRoute user={user} path="/profile">
              <Navigation user={user} />
              <Profile />
            </PrivateRoute>
            <PrivateRoute user={user} path="/setup">
              <Navigation user={user} />
              <SetupOrg user={user} />
            </PrivateRoute>
            <PrivateRoute user={user} path="/dashboard">
              <Navigation user={user} />
              <Dashboard />
            </PrivateRoute>
            <PrivateRoute user={user} path="/settings">
              <Navigation user={user} />
              <Settings />
            </PrivateRoute>
            <Route path="/" component={Home} exact />
            <Route render={() => <Redirect to={{ pathname: "/" }} />} />
          </Switch>
        </div>
        <hr />
        <Footer user={user} />
      </Suspense>
    </Router>
  );
}
