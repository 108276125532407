import React from "react"
import {Form, Button, ButtonGroup} from "react-bootstrap";
import {emojiFor} from  "../helpers/utils"

export default function LocationSelect(props) {
    return (
      <Form.Group controlId="location" className="text-center">
        <Form.Text>Where are you today?</Form.Text>
  
        <ButtonGroup aria-label="location" style={{backgroundColor: "white"}}>
          <Button
            onClick={event =>
              props.updateProfile("location", event.currentTarget.value)
            }
            value="office"
            variant={
              props.location !== "office" ? "outline-secondary" : "primary"
            }
          >
            <span className="d-block" role="img" aria-label="office emoji">
              🏢
            </span>{" "}
            Office
          </Button>
          <Button
            onClick={event =>
              props.updateProfile("location", event.currentTarget.value)
            }
            value="home"
            variant={props.location !== "home" ? "outline-secondary" : "primary"}
          >
            <span role="img" className="d-block" aria-label="house emoji">
              {emojiFor("home")}
            </span>{" "}
            Home
          </Button>
          <Button
            onClick={event =>
              props.updateProfile("location", event.currentTarget.value)
            }
            value="mobile"
            variant={
              props.location !== "mobile" ? "outline-secondary" : "primary"
            }
          >
            <span role="img" className="d-block" aria-label="car emoji">
              {emojiFor("mobile")}
            </span>
            Mobile
          </Button>
          <Button
            onClick={event =>
              props.updateProfile("location", event.currentTarget.value)
            }
            value="other"
            variant={props.location !== "other" ? "outline-secondary" : "primary"}
          >
            Other
          </Button>
        </ButtonGroup>
      </Form.Group>
    );
  }