import firebase from "../firebase";
import { Badge } from "react-bootstrap";
import React from "react";

export const _MS_PER_DAY = 1000 * 60 * 60 * 24;

export const getDomain = () => {
  return firebase.auth().currentUser.email.replace(/.*@/, "");
};

export const emojiFor = state => {
  switch (state) {
    case "checked-out":
      return "👋";
    case "unwell":
      return "🤒";
    case "home":
      return "🏡";
    case "mobile":
      return "🚗";
    case "holiday":
      return "🏕";
    case "office":
      return "🏢";
    case "non-working-day":
      return "🏄🏻‍♀️";
    case "working":
      return "👨🏻‍💻";
    case "other":
      return "";

    default:
      return "Not provided";
  }
};

export const pillForStatus = state => {
  switch (state) {
    case "available":
      return <Badge variant="success">Available</Badge>;
    case "busy":
      return <Badge variant="danger">Busy</Badge>;
    case "away":
      return <Badge variant="warning">Away</Badge>;
    default:
      return "Not provided";
  }
};

export const daysSinceJoining = org => {
  const a = org.joined.toDate();
  const b = new Date();
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
};

export const groupBy = (list, keyGetter) => {
  const map = new Map();
  list.forEach(item => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};

export const addOrRemove = (array, item) => {
  const exists = array.includes(item);

  if (exists) {
    return array.filter(c => {
      return c !== item;
    });
  } else {
    const result = array;
    result.push(item);
    return result;
  }
};
