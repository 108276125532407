import React from "react";
import { Container } from "react-bootstrap";

export default function PrivacyPolicy() {
  return (
    <Container>
      <h1 className="mb-3">Privacy Policy</h1>
      <h2>The types of information collected by this website</h2>
      <p>By using this service you are providing your email address and will be required to submit various pieces of personal information relating to where you're working from, if you're working and availablity. If you interact with the service, various pieces of information will be collected and stored to make the application function as intended.</p>
      <h2>The purpose for collecting the data.</h2>
      <p>
        The data is collected to aid companies and colleagues work together
        better remotely.
      </p>
      <p>
        Rough statistics of overall usage maybe used to inform further
        development.
      </p>
      <p>Email addresses maybe utilised to send out information regarding the product. These will be spent spairingly and only in the event of service distruction, termination of service or important information.</p>
      <h2>Data storage, security and access.</h2>
      <p>
        Data is stored in Google Cloud Platform (europe-west2) using Firestore
        product and secured by Firestore rules.
      </p>
      <h2>Details of data transfers.</h2>
      <p>
        Data is transferred over SSL using LetsEncrypt certificates. Data
        transfer inside Google Cloud Platform details can be found on their
        site.
      </p>
      <h2>Affiliated websites or organizations (third parties included)</h2>
      <p>There are no afflicated websites or services with this product.</p>
      <h2>Use of cookies.</h2>
      <p>
        Cookies are used to log you in and keep you logged into the service.
      </p>
    </Container>
  );
}
