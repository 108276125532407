import React, { Component } from "react";
import { emojiFor } from "../helpers/utils";
import { Button, Form, ButtonGroup, Popover, OverlayTrigger } from "react-bootstrap";
import AvailabilitySelect from "./AvailabilitySelect";
import LocationSelect from "./LocationSelect";

const popover = (
  <Popover id="popover-basic">
    <Popover.Title as="h3"><strong>Try me</strong></Popover.Title>
    <Popover.Content>
      And watch the live dashboard update above
    </Popover.Content>
  </Popover>
);

export default class YourStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      demoUserProfile: {
        name: "Amy Scott",
        condition: "checked-out",
        location: "office",
        status: "available"
      }
    };
  }

  updateDemoProfile = (param, optional) => {
    if (optional) {
      param = { [param]: optional };
    }
    this.setState({
      demoUserProfile: Object.assign(this.state.demoUserProfile, param)
    });
  };

  render() {
    const profile = this.props.profile;

    return (
      <React.Fragment>
        <Button disabled variant="link">
          <span style={{ fontSize: "2em" }} className="mb-3">
            Amy Scott
          </span>
          <br />
          <span>
            <em>Finance</em>
          </span>
        </Button>
        {profile.condition === "working" ? (
          <React.Fragment>
            <Form.Text className="text-center">
              What’s your status today?
            </Form.Text>
            <Button
              block
              variant="success"
              size="lg"
              onClick={event =>
                this.props.handleChangeStatus({ condition: "checked-out" })
              }
            >
              <span role="img" className="d-block" aria-label="person emoji">
                {emojiFor("checked-out")}
              </span>
              Check out
            </Button>
            <LocationSelect
              location={profile.location}
              updateProfile={this.props.handleChangeStatus}
            />

            <AvailabilitySelect
              availability={profile.status}
              updateProfile={this.props.handleChangeStatus}
            />
          </React.Fragment>
        ) : (
          <Form.Group aria-label="condition" className="text-center">
            <Form.Text>What’s your status today?</Form.Text>
            <OverlayTrigger
              defaultShow={window.screen.width < 992 ? false : true}
              placement="right"
              overlay={popover}
            >
              <Button
                block
                size="lg"
                onClick={() => this.props.handleChangeStatus({ condition: "working" })}
              >
                <span role="img" className="d-block" aria-label="person emoji">
                  {emojiFor("working")}
                </span>
                Check in
              </Button>
            </OverlayTrigger>
            <hr className="hr-text" data-content="OR" />

            <ButtonGroup aria-label="Condition" style={{backgroundColor: "white"}}>
              <Button
                value="non-working-day"

                variant={
                  profile.condition !== "non-working-day"
                    ? "outline-secondary"
                    : "primary"
                }
                onClick={event =>
                  this.props.handleChangeStatus({
                    condition: event.currentTarget.value
                  })
                }
              >
                <span role="img" className="d-block" aria-label="person emoji">
                  {emojiFor("non-working-day")}
                </span>{" "}
                Non working day
              </Button>
              <Button
                value="holiday"
                variant={
                  profile.condition !== "holiday"
                    ? "outline-secondary"
                    : "primary"
                }
                onClick={event =>
                  this.props.handleChangeStatus({
                    condition: event.currentTarget.value
                  })
                }
              >
                <span role="img" className="d-block" aria-label="holiday emoji">
                  {emojiFor("holiday")}
                </span>{" "}
                Holiday
              </Button>
              <Button
                value="unwell"
                variant={
                  profile.condition !== "unwell"
                    ? "outline-secondary"
                    : "primary"
                }
                onClick={event =>
                  this.props.handleChangeStatus({
                    condition: event.currentTarget.value
                  })
                }
              >
                <span role="img" className="d-block" aria-label="unwell emoji">
                  {emojiFor("unwell")}
                </span>{" "}
                Unwell
              </Button>
            </ButtonGroup>
          </Form.Group>
        )}
      </React.Fragment>
    );
  }
}
