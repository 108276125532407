import React from "react";
import { Container, Button } from "react-bootstrap";

export default function WelcomeNewUser(props) {
  return (
    <Container
      fluid
      className="text-white p-4"
      style={{ backgroundColor: "#ee4266" }}
    >
      <h2>Welcome to Working Status</h2>

      <p>
        <strong>
          You've been invited to share your your availability online with your
          team.
        </strong>
      </p>

      <h3>Getting Started</h3>

      <p>
        Simply fill out your name, choose your department below and head on
        over to the <strong>Dashboard</strong> to catch up with your team.
      </p>

      <p>
        If you've any problems at all, or you'd like to suggest a feature, get
        in touch. We're here to help.
      </p>

      <p>
        <em>&mdash; Working Status Team</em>
      </p>
      <Button className="text-right" onClick={() => props.setShowing(false)}>
        Close
      </Button>
    </Container>
  );
}
