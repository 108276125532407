import React, { Component } from "react";
import { Container, Col, Row, Nav, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LinkContainer } from "react-router-bootstrap";
import LiveDashboard from "../../component/LiveDashboard";
import SignInRow from "../../component/SignInRow";
import YourStatus from "../../component/YourStatus";
import firebase from "../../firebase";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon
} from "react-share";

export default class Home extends Component {
  constructor(props) {
    super(props);

    const rememberedEmail = localStorage.getItem("email", "");
    
    this.state = {
      email: rememberedEmail,
      emailSent: false,
      demoUserProfile: {
        name: "Amy Scott",
        condition: "checked-out",
        location: "office",
        status: "available"
      }
    };
  }

  updateDemoProfile = (param, optional) => {
    if (optional) {
      param = { [param]: optional };
    }
    this.setState({
      demoUserProfile: Object.assign(this.state.demoUserProfile, param)
    });
  };

  render() {
    return (
      <React.Fragment>
        <Container
          style={{ backgroundColor: "#6f42c1" }}
          className="m-0 p-4 home"
          fluid
        >
          <Nav className="justify-content-end">
            {firebase.auth().currentUser &&
            <Nav.Item>
              <LinkContainer to="/dashboard">
                <Nav.Link className="text-light">Dashboard</Nav.Link>
              </LinkContainer>
            </Nav.Item>}
            <Nav.Item>
              <LinkContainer to="/pricing">
                <Nav.Link className="text-light">Pricing</Nav.Link>
              </LinkContainer>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                className="text-light"
                href="mailto:chris@onebrightspace.com"
              >
                <FontAwesomeIcon icon="envelope-open" /> Get in touch
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Row>
            <Col xs="auto">
              <img
                alt="Logo of working status"
                className="rounded align-top"
                src="/imgs/icon.svg"
                width="85"
                height="85"
              />
            </Col>
            <Col>
              <h1 className="text-light display-3 pb-0 mb-0">Working Status</h1>
              <h2 className="mt-0">
                <small className="text-light">
                  &#8212; Simple status sharing
                </small>
              </h2>
            </Col>
          </Row>

          <Row className="text-white mt-4 mb-4 justify-content-center align-items-center">
            <Col xs="12" md="6" lg="4">
              <h2>Online sign in and out sheet.</h2>
              <ul style={{ fontSize: "1.3em" }}>
                <li>A simple, fuss free way of checking in and out of work</li>
                <li>See who's available in just one place</li>
                <li>Share your availability instantly</li>
              </ul>
            </Col>
            <Col md="6" className="d-xs-none d-md-block text-center">
              <Image
                style={{ backgroundColor: "#fff" }}
                className="p-3 rounded"
                width="400"
                height="340"
                srcSet="/imgs/FrontPageSketch.png 361w,
                       /imgs/FrontPageSketch@2.png 614w"
              />
            </Col>
          </Row>
        </Container>
        <SignInRow />
        <Container fluid>
          <LiveDashboard
            demoPerson={this.state.demoUserProfile}
            isUpdating={true}
          />
          <Row className="p-4" style={{ backgroundColor: "#efefef" }}>
            <Col>
              <Row>
                <Col lg={8}>
                  <h3 className="display-4 text-dark">
                    <FontAwesomeIcon color="#bbb" icon="id-badge" /> Share your
                    status
                  </h3>
                  <h5 className="">Tell others you're working and where</h5>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col md={8} lg={5} className="text-center example">
                  <YourStatus
                    profile={this.state.demoUserProfile}
                    handleChangeStatus={this.updateDemoProfile}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <hr />
          
          <Row className="justify-content-center align-content-center ">
            <Col
              lg={8}
              className="rounded p-4"
              style={{ backgroundColor: "#ffd23f", color: "#540d6e" }}
            >
              <h3 className="display-4 ">
                <FontAwesomeIcon className="text-white" icon="star" /> Features
              </h3>
              <Row>
                <Col xs="12" md="6" style={{ fontSize: "1.3em" }}>
                  <ul>
                    <li>Realtime dashboard</li>
                    <li>Quick and simple status update</li>
                    <li>Resizes to work on mobile and tablet</li>
                    <li>Search and filter</li>
                    <li>Customise organization structure</li>
                    <li>Expiration of aged updates</li>
                  </ul>
                </Col>
                <Col xs="12" md="6" style={{ fontSize: "1.3em" }}>
                  <ul>
                    <li>
                      Notify when available <sup>coming soon</sup>
                    </li>
                    <li>
                      Favourites <sup>coming soon</sup>
                    </li>
                    <li>Warnings when staffing levels drop below thresholds</li>
                    <li>
                      Export statistics <sup>coming soon</sup>
                    </li>
                    <li>
                      iOS and Android app <sup>coming soon</sup>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
          <hr />
          <Row className="p-4">
            <Col xs="12" md="4">
              <h2 className="text-dark">
                <FontAwesomeIcon icon="laptop-house" />
                <br />
                Simple
              </h2>
              <p>
                Made with speed and simplicity in mind. Our aim is to empower
                colleagues working remotely, in the field or in the office to be
                better connected.
              </p>
              <p>
                A single place to quickly check who's in in your team, if they
                can take a call or if you're fully staffed in a specific
                department.
              </p>
            </Col>

            <Col xs="12" md="4">
              <h2 className="text-dark">
                <FontAwesomeIcon icon="server" />
                <br />
                Modern
              </h2>
              <p>
                Built with modern technology which updates without the need to
                refresh your browser.
              </p>
              <p>
                We're reliable, stable and hosted on a major Cloud provider
                using an elastic infrastructure.
              </p>
            </Col>
            <Col xs="12" md="4">
              <h2 className="text-dark">
                <FontAwesomeIcon icon="user-shield" />
                <br />
                Secure
              </h2>
              <p>
                Simply enter your <em>work email</em> and we'll create an
                account for you, if your organsiation has not already joined.
              </p>
              <p>
                Only people in the same organization (
                <a href="https://en.wikipedia.org/wiki/Fully_qualified_domain_name">
                  fully qualfiied domain
                </a>
                ) are associated with your team.
              </p>

              <p>
                Read our <Link to="/privacy">privacy policy</Link> to see how we
                handle your data securely &amp; transparently.
              </p>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col lg={12}>
              <h3 className="display-4 text-center text-dark">
                <a href="#sign-in-row">Sign in</a> and give it a go...
              </h3>
              <h5 className="text-right text-center">
                Have a question?{" "}
                <a href="mailto:chris@onebrightspace.com">Get in touch</a>
              </h5>
            </Col>
          </Row>
          <hr />
          <Row className="justify-content-center">
            <Col xs="auto">
              <TwitterShareButton className="mx-2" url="https://workingstatus.app">
                <TwitterIcon size={70} round="true" />
              </TwitterShareButton>
              <FacebookShareButton className="mx-2" url="https://workingstatus.app">
                <FacebookIcon size={70} round="true" />
              </FacebookShareButton>
              <LinkedinShareButton className="mx-2" url="https://workingstatus.app">
                <LinkedinIcon size={70} round="true" />
              </LinkedinShareButton>
              <EmailShareButton className="mx-2" url="https://workingstatus.app">
                <EmailIcon size={70} round="true" />
              </EmailShareButton>
              <WhatsappShareButton className="mx-2" url="https://workingstatus.app">
                <WhatsappIcon size={70} round="true" />
              </WhatsappShareButton>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
