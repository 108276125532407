import React, { useState } from "react";
import { Button, Navbar, Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import firebase from "../firebase";
import Messages from "./Messages"

export default function Navigation(props) {
  const [admin, setAdmin] = useState(false);
  let history = useHistory();

  const signOut = () => {
    firebase.auth().signOut();
    history.push("/");
  };

  if (props.user) {
    props.user
      .getIdTokenResult()
      .then(idTokenResult => {
        if (!!idTokenResult.claims.admin) {
          setAdmin(true);
        } else {
          setAdmin(false);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  return (
    <React.Fragment>
      <Messages user={props.user} />
      <Navbar bg="light" variant="light">
        <Navbar.Brand>
          <img
            src="/imgs/icon.svg"
            width="30"
            height="30"
            className="d-inline-block rounded"
            alt="Working status logo"
          />
        </Navbar.Brand>
        {props.user ? (
          <Nav className="mr-auto">
            <LinkContainer to="/profile">
              <Nav.Link>My Status</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/dashboard">
              <Nav.Link>Dashboard</Nav.Link>
            </LinkContainer>
          </Nav>
        ) : (
          <Nav className="mr-auto">
            <LinkContainer to="/home">
              <Nav.Link>Home</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/pricing">
              <Nav.Link>Pricing</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/contact">
              <Nav.Link
                className="text-light"
                href="mailto:chris@onebrightspace.com"
              >
                Get in touch
              </Nav.Link>
            </LinkContainer>
          </Nav>
        )}
        {admin && (
          <LinkContainer to="/settings/general">
            <Nav.Link>
              <FontAwesomeIcon icon="cog" />
            </Nav.Link>
          </LinkContainer>
        )}
        {props.user && (
          <Button variant="link" onClick={signOut}>
            Sign out
          </Button>
        )}
      </Navbar>
      <hr />
    </React.Fragment>
  );
}
